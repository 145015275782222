import React from "react";
import { graphql } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout/Layout";
import QuoteRow from "../components/QuoteRow";
import { LogoCloud } from "../components/LogoCloud";

import { pushSAEvent, pushToGTM, setCookie, postFBEvent } from "../helpers";

export default ({
    data,
    location
}) => {
    const { loginWithRedirect } = useAuth0();

    const params = new URLSearchParams(location.search);
    const acronisTrial = params.get('type') === 'acronis';

    const handleRegisterButtonClick = () => {
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Trial Form Submit',
            'resource_name': 'Trial'
        });

        if (typeof window !== "undefined" && window.fbq != null) {
            window.fbq('track', 'CompleteRegistration');
        }

        pushSAEvent("submit_trial");

        postFBEvent("StartTrial", "Trial Form");

        setCookie('frends_visitor_status', 'free_trial', 60);

        setCookie('trial_type', 'acronis', (acronisTrial ? 60 : -1));

        loginWithRedirect({
            authorizationParams: {
                screen_hint: "signup",
                redirect_uri: `${window.location.origin}/profile/trial/verify-email`
            }
        });
    };

    pushSAEvent("visit_trial");

    return (
        <Layout
            title="Trial"
            transparentNavigation={true}
            location={location}
        >
            <main
                id="register-trial-content"
                style={{ marginTop: "-80px" }}
            >
                <Hero
                    acronisTrial={acronisTrial}
                    bg={data.hero_bg}
                    onRegisterButtonClick={handleRegisterButtonClick}
                />

                {acronisTrial && (
                    <AcronisTaskCards />
                )}

                <Reviews />
                <UserQuote />

                {!acronisTrial && (
                    <CustomerLogos logos={data.contentfulLogoCloud.logo} />
                )}

                <LaunchNowCTA
                    bg={data.cta_bg}
                    onRegisterButtonClick={handleRegisterButtonClick}
                />
            </main>
        </Layout>
    );
};

const Hero = ({
    acronisTrial,
    bg,
    onRegisterButtonClick,
}) => {
    const getTitle = () => {
        if (acronisTrial) {
            return 'Frends for Acronis';
        }
        return '30-day free trial';
    };

    const getDescription = () => {
        if (acronisTrial) {
            return 'Experience Seamless Acronis Integrations with Frends iPaaS - Free for 30 Days';
        }
        return 'Register here to receive your very own Frends Trial';
    };

    return (
        <section
            className="section is-medium"
            style={{ position: 'relative', paddingTop: '16.375rem', paddingBottom: '7.625rem' }}
        >
            <GatsbyImage
                image={getImage(bg)}
                style={{ width: "100%", position: "absolute", bottom: "0", left: "0", zIndex: "-1", height: "100%" }}
                alt=""
            />

            <div className="container is-flex is-flex-direction-column is-align-items-center has-text-centered">
                <h1
                    className="font-bold size-100 is-white"
                    style={{ lineHeight: 'normal' }}
                >
                    {getTitle()}
                </h1>

                <p
                    className="is-white font-regular size-40"
                    style={{ marginTop: '3.438rem', maxWidth: '762px', lineHeight: 'normal' }}
                >
                    {getDescription()}
                </p>

                <button
                    className="frends-button font-variable font-width-variation-850 rounded is-outlined-circle is-white"
                    style={{ marginTop: '3.438rem' }}
                    onClick={onRegisterButtonClick}
                >
                    <span>
                        register
                    </span>
                </button>
            </div>
        </section >
    );
}

const AcronisTaskCards = () => (
    <section className="section acronis-trial-cards-section">
        <h2 className="has-text-centered size-40">
            Integrate and Automate Manual Acronis Tasks with Frends iPaaS
        </h2>

        <ul className="acronis-trial-card-list">
            <li>
                <AcronisTaskCard
                    title="Automate Tenant Management"
                    paragraphs={[
                        'Get the capability to manage tenants effortlessly, whether it\'s creating, updating, or switching them to production.',
                        'With Frends, you can say goodbye to manual processes and hello to true efficiency. Whether you prefer API connectivity or a user-friendly UI, we\'ve got you covered.'
                    ]}
                    listTitle="Experience:"
                    listItems={[
                        'Faster response times',
                        'Reduced workload',
                        'Enhanced service quality',
                    ]}
                />
            </li>
            <li>
                <AcronisTaskCard
                    title="Simplify User Account Operations"
                    paragraphs={[
                        'Every day brings its share of user account tasks, and Frends is here to make them a breeze.',
                        'From creating and updating users to managing their access and activation, we\'ve streamlined it all. Accessible via APIs or our intuitive UI, your daily operations will be smoother than ever before.'
                    ]}
                    listTitle="Enjoy:"
                    listItems={[
                        'Quick account updates',
                        'Greater control over access policies',
                        'Easy user activation',
                    ]}
                />
            </li>
            <li>
                <AcronisTaskCard
                    title="Effortless Application Management"
                    paragraphs={[
                        'Managing end customers\' applications has never been easier.',
                        'Frends allows you to effortlessly list and activate applications for tenants, all with the flexibility to use either our UI or APIs.',
                    ]}
                    listTitle="Experience a new level of ease as you:"
                    listItems={[
                        'Get a comprehensive  list of available applications for tenants',
                        'Activate applications with unprecedented simplicity'
                    ]}
                />
            </li>
            <li>
                <AcronisTaskCard
                    title="Seamless Acronis Integration"
                    paragraphs={[
                        'Advance your current Aconis monitoring and alerting capabilities through Frends iPaaS',
                        'Frends seamlessly integrates with Acronis, allowing you to automate alerts and easily connect them to your backend systems.',
                    ]}
                    listTitle="Explore the freedom to:"
                    listItems={[
                        'Get usage reports and insights',
                        'Dismiss or close alerts swiftly'
                    ]}
                />
            </li>
        </ul>
    </section>
);

export const AcronisTaskCard = ({
    title,
    paragraphs,
    listTitle,
    listItems,
    customStyles,
}) => (
    <article
        className="acronis-trial-card"
        style={{...(customStyles ? customStyles: {})}}
    >
        <h3 className="size-28 is-dark-pink">
            {title}
        </h3>

        {paragraphs.map((p, i) => (
            <p
                key={i}
                className="size-20"
            >
                {p}
            </p>
        ))}

        <h3
            className="size-22"
            style={{ fontFamily: 'Gilroy-Medium' }}
        >
            {listTitle}
        </h3>

        <ul>
            {listItems.map((item, i) => (
                <li
                    key={i}
                    className="size-22"
                >
                    {item}
                </li>
            ))}
        </ul>
    </article>
);

const Reviews = () => (
    <section
        className="section customer-quotes"
        style={{ paddingLeft: '0px', paddingRight: '0px' }}
    >
        <QuoteRow id="y-u9CoHay" />
    </section>
);

const UserQuote = () => (
    <section className="section">
        <div
            className="blockquote is-pink"
            style={{ marginTop: 0, marginBottom: 0 }}
        >
            <blockquote>
                Frends is extremely easy to use, very visual and basically self-documenting. I can make an integration or API in one afternoon and still have time for a coffee break.
            </blockquote>

            <p>
                Information System Manager on using Frends
            </p>
        </div>
    </section>
);

const CustomerLogos = ({ logos }) => (
    <LogoCloud
        titlePart1="Join the ranks"
        titlePart2="of these industry leaders"
        logos={logos}
        style={{ backgroundColor: '#f6faf9' }}
    />
);

const LaunchNowCTA = ({
    bg,
    onRegisterButtonClick
}) => (
    <section
        className="section"
        style={{ position: 'relative', paddingTop: '12rem', paddingBottom: '12rem' }}
    >
        <GatsbyImage
            image={getImage(bg)}
            style={{ width: "100%", position: "absolute", bottom: "0", left: "0", zIndex: "-1", height: "100%" }}
            alt=""
        />

        <div className="container has-text-centered">
            <h2
                className="size-40"
                style={{ color: '#8F1459' }}
            >
                <span>
                    Start your 30-Day
                </span>
                <br />
                <span>
                    Free Trial Today
                </span>
            </h2>

            <button
                className="frends-button font-variable font-width-variation-850 rounded is-outlined-circle is-dark-pink trial-register"
                style={{ marginTop: '4.375rem' }}
                onClick={onRegisterButtonClick}
            >
                <span>
                    register
                </span>
            </button>
        </div>
    </section>
);

export const query = graphql`query {
    hero_bg: file(relativePath: {eq: "images/trial_hero_bg.png"}) {
        childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
    }

    contentfulLogoCloud (
        logoCloudName: {eq: "FRENDS V2 Logo Cloud"}
        node_locale: {eq: "en-US"}
      ) {
        logo {
            title
            file {
                url
                details {
                    image {
                        height
                        width
                    }
                }
            }
        }
    }

    cta_bg: file(relativePath: {eq: "images/trial_cta_bg.png"}) {
        childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
    }
}`;